<template>
    <div>
      <!-- <Header /> -->
      <CapaImoveis />
    </div>
  </template>
  
  <script>
    // import Header from "../../components/HeaderLogin.vue";
    import CapaImoveis from "../../components/Imoveis/CapaImoveis.vue"
  
  export default {
    components: {
      // Header,
      CapaImoveis,
    }
  }
  </script>
  