<template>
  <div class="centraliza">
    <div id="capa">
      <div id="impressao">
        <div class="fundobranco">
          <v-card outlined class="alinhacentro">
            <h2> {{ nomecliente }} | {{ telefonecliente }} </h2>
            <h2> {{ tipo_negocio }} | {{ tipo_imovel }} | {{ formatarValor(imovel) }}</h2>
            <h2> {{ subtipo_imovel }}</h2>
          </v-card>
          <v-card outlined class="alinhacentro">
            <img :src="imagembase64" alt="Sem imagem" width="739px" height="400px" />
          </v-card>
          <v-card outlined class="alinhacentro">
            <h2> {{ cidade }}/{{ uf }} - {{ bairro }}</h2>
          </v-card>
          <v-card outlined class="alinhacentro">
            <h2> Dados do imóvel</h2><br>
            <h3> {{ imovel.descricao }}</h3><br>
            <h3> Área Útil: {{ formatarArea(imovel.areautil) }} m² | Área Total: {{ formatarArea(imovel.areatotal) }} m² </h3>
            <h3> Suítes: {{ imovel.suites }} | Quartos: {{ imovel.quartos }} </h3>
            <h3> Vagas de Garagem: {{ imovel.garagens }} | Ambientes: {{ imovel.ambientes }} </h3>
          </v-card>
          <v-card outlined class="mt-5 mb-5 alinhacentro">
            <img :src="qrcode" />
            <h5>Aponte a câmera do celular e inicie a visita virtual</h5>
          </v-card>
        </div>
      </div>        
      <v-card class="mt-5 mb-5 elevation-0">
          <v-btn id="botao" height="50px" width="135px" class="ml-2 mb-2" :disabled="(link == null) || (link.trim() == '')"
            color="green darken-1 white--text" @click="visualizaTour">
            <h4>Iniciar<br>Visita Virtual</h4>
          </v-btn>
          <v-btn height="50px" width="135px" class="ml-2 mb-2" color="red darken-1 white--text" @click="imprimir">
            <h4>Imprimir Capa</h4>
          </v-btn>
          <br>
          <v-btn height="50px" width="135px" class="ml-2 mb-2" color="yellow darken-1 white--text">
            <v-speed-dial v-model="dialShare" direction="top" open-on-click class="ml-1">
              <template v-slot:activator>
                <v-btn height="50px" width="135px" color="yellow darken-1 white--text elevation-0">
                  <h4 v-if="dialShare">Fechar</h4>
                  <h4 v-else>Compartilhar</h4>
                  <template v-slot:activator="{ on, attrs }">
                  </template>
                </v-btn>
              </template>
              <v-card width="240px">
                <v-col>
                  <v-btn dark fab bottom color="green" small :href="`whatsapp://send?text=${formataTexto('w')}`" target="_blank">
                    <svg-icon width="20px" type="mdi" :path="whatsapp"></svg-icon>
                  </v-btn>
                  <v-btn dark fab bottom color="blue" small :href="`https://www.facebook.com/sharer/sharer.php?u=${formataTexto('f')}`" target="_blank">
                    <svg-icon height="20px" width="20px" type="mdi" :path="facebook"></svg-icon>
                  </v-btn>
                  <v-btn dark fab bottom color="blue darken-7" small :href="`https://www.linkedin.com/sharing/share-offsite?url=${formataTexto('l')}`" target="_blank">
                    <svg-icon height="20px" width="20px" type="mdi" :path="linkedin"></svg-icon>
                  </v-btn>
                  <v-btn dark fab bottom color="tertiary" small :href="`mailto:?subject=[VisitaImoveis360] Visita Virtual!&amp;body=${formataTexto('e')}`" target="_blank">
                    <svg-icon height="20px" width="20px" type="mdi" :path="email"></svg-icon>
                  </v-btn>
                </v-col>
              </v-card>
            </v-speed-dial>
          </v-btn>
          <v-btn height="50px" v-if="this.history == 1" width="135px" class="ml-2 mb-2" color="blue darken-1 white--text" @click="fechar">
            <h4>Fechar</h4>
          </v-btn>
      </v-card>
    </div>
  </div>
</template>
  
<script>
import svcImoveis from "../../services/svcImoveis";
import svcClientes from "../../services/svcClientes";
import svcCidades from "../../services/svcCidades";
import svcBairros from "../../services/svcBairros";
import SvgIcon from '@jamescoyle/vue-icon';
import { decriptar } from "../../config/cripto";
import { descTipoNegocio, descTipoImovel, descSubTipoImovel, formataArea, formataValor } from "../../config/funcoes"
import { mdiShareVariant, mdiCloseThick, mdiWhatsapp, mdiFacebook, mdiLinkedin, mdiEmail } from "@mdi/js";

export default {
  components: {
    SvgIcon
  },
  data() {
    return {
      whatsapp: mdiWhatsapp,
      facebook: mdiFacebook,
      linkedin: mdiLinkedin,
      email: mdiEmail,
      share: mdiShareVariant,
      closeshare: mdiCloseThick,
      imovel: {
        id: "",
        id_cliente: 0,
        descricao: "",
        tipo_negocio: 1,
        id_cidade: 0,
        id_bairro: 0,
        tipo_imovel: 1,
        subtipo_imovel: 1,
        valor: 0,
        suites: 0,
        quartos: 0,
        ambientes: 0,
        areautil: 0,
        areatotal: 0,
        imagem: "",
        pasta_arquivos: "",
      },
      imagembase64: "",
      nomecliente: "",
      telefonecliente: "",
      cpfcnpjcliente: "",
      tipo_negocio: "",
      tipo_imovel: "",
      subtipo_imovel: "",
      cidade: "",
      uf: "",
      bairro: "",
      link: "",
      history: 0,
      dialShare: false,
      windowHeight: 0,
    }
  },

  computed: {
    qrcode() {
      var QRCode = require("qrcode");
      var base64 = "";
      QRCode.toDataURL(this.link, function (err, code) {
        if (err) {
          console.log(err);
        } else {
          base64 = code;
        }
      });
      return base64;
    }
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    },

    formataTexto(tipo) { // tipos: w-whatsapp, f-facebook, l-linkedin, e-email
      var texto = "";
      if ((tipo == "w") || (tipo == "e")) {
        texto = this.nomecliente + " | " + this.telefonecliente + "\n\n" +
                this.imovel.descricao + "\n" +
                this.cidade + "/" + this.uf + " - " + this.bairro + "\n\n" +
                "Área Útil: " + formataArea(this.imovel.areautil) + " m² \n" +
                "Área Total: " + formataArea(this.imovel.areatotal) + " m² \n" +
                "Suítes: " + this.imovel.suites + "\n" +
                "Quartos: " + this.imovel.quartos + "\n" +
                "Vagas de Garagem: " + this.imovel.garagens + "\n" +
                "Ambientes: " + this.imovel.ambientes + "\n\n" +
                formataValor(this.imovel) + "\n\n" +
                "Visita Virtual em:" + "\n" +
                window.location.protocol +
                "//" +
                window.location.host +
                "/#/imoveis/capa/" +
                this.$route.params.id;
      } else {
        texto = window.location.protocol +
                "//" +
                window.location.host +
                "/#/imoveis/capa/" +
                this.$route.params.id;
      }

      if ((tipo == "w") || (tipo =="e")) {
        return window.encodeURIComponent(texto);
      } else {
        return texto;
      }
    }, 

    formatarValor(item) {
      return formataValor(item);
    },

    formatarArea(item) {
      return formataArea(item);
    },

    visualizaTour() {
      window.open(this.link, '_blank');
    },

    imprimir() {
      var impressao = document.getElementById('impressao').innerHTML;
      document.getElementsByTagName('body')[0].innerHTML = impressao;
      window.print();
      location.reload()
    },

    fechar() {
      close();
    },

    pesquisa(id) {
      svcImoveis
        .pesquisarficha(id)
        .then((resp) => {
          this.imovel = resp.data;
          if ((this.imovel.imagem == null) || (this.imovel.imagem.trim() == "")) {
            this.imagembase64 = require("@/assets/logopadrao.png");
          } else {
            this.imagembase64 = process.env.VUE_APP_URL_FILES + "/l/" + this.imovel.imagem;
          }
          this.pesquisaCliente(this.imovel.id_cliente);
          this.pesquisaCidade(this.imovel.id_cidade);
          this.pesquisaBairro(this.imovel.id_bairro);
          this.tipo_negocio = descTipoNegocio(this.imovel);
          this.tipo_imovel = descTipoImovel(this.imovel);
          this.subtipo_imovel = descSubTipoImovel(this.imovel);
        })
        .catch((error) => {
          alert("Erro ao consultar imóvel: " + error);
        });
    },

    async pesquisaCliente(id) {
      svcClientes
        .pesquisarficha(id)
        .then((resp) => {
          this.nomecliente = resp.data.nome;
          this.telefonecliente = resp.data.telefone;
          if ((this.imovel.pasta_arquivos == null) || (this.imovel.pasta_arquivos.trim() == "")) {
            this.link = "";
          } else {
            this.link = process.env.VUE_APP_URL_FILES + "/t/" + resp.data.cpfcnpj + "/" + this.imovel.pasta_arquivos + "/app-files";
          }
        })
        .catch((error) => {
          alert(error);
        });
    },

    pesquisaCidade(id) {
      svcCidades
        .pesquisarficha(id)
        .then((resp) => {
          this.cidade = resp.data.nome;
          this.uf = resp.data.uf;
        })
        .catch((error) => {
          alert(error);
        });
    },

    pesquisaBairro(id) {
      svcBairros
        .pesquisarficha(id)
        .then((resp) => {
          this.bairro = resp.data.nome;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },

  mounted() {
    this.history = window.history.length;
    this.pesquisa(decriptar(this.$route.params.id));
  }
}

</script>
  
<style scoped>
#capa {
  width: 800px;
  padding: 0px 30px 0px 30px;
  background-color: white;
}

.centraliza {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 800px;
}

.alinhacentro {
  text-align: center;
  margin-top: 10px;
}

.fundobranco {
  background-color: white;
}
</style>
  